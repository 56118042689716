import React, { useEffect, useMemo, useRef, useState } from "react";
import shallow from "zustand/shallow";
import clsx from "clsx";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Grid,
  FormControl,
  MenuItem,
  makeStyles,
  Typography,
  InputLabel,
  OutlinedInput,
  Box,
  Menu,
  Button
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { find, isEmpty, map, random, sample, size, sortBy, toLower } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { EnvDto } from "@rapidcanvas/rc-api-core";

import Text from "src/components/Widget/Text";
import { useEnvironments } from "src/hooks";
import { validateNameField } from "src/utils/formFieldUtils";
import { Field, OverflowTooltip } from "src/components";
import Modal from "src/components/custom/Modal/Modal";
import useTenantsStore from "src/stores/tenant-management.store";
import { useProjectsStore } from "src/store/store";
import { envMetadataFind, imageToBase64 } from "../helpers/projects.helpers";
import { DEFAULT_NAME } from "src/constants/environments.constants";
import { statusValues } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import EnvironmentTypeConfig from "src/pages/private/EnvironmentsModule/pages/common/EnvironmentTypeConfig";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";
import styles from "src/pages/private/EnvironmentsModule/pages/common/Environments.module.scss";
import default_image from "src/assets/images/projectImages/default_project_thumbnail1.svg";
import PreviewImageSelectorNew from "../ProjectSettings/PreviewImageSelectorNew";
import { offlineImages } from "src/pages/Projects/ProjectSettings/PreviewImageModal";
import { WebPaths } from "src/routing/routes";
import ConfirmCloseWindow from "./ConfirmCloseWindow";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SelectWithLoading from "src/components/Select/SelectWithLoading";
import { useGetEnvironmentTypes } from "src/hooks/api";
import useCreateProject from "src/hooks/api/projects/useCreateProject";
import { useProjectNameSuggestion } from "./useProjectNameSuggestion";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage,
  cdnImagePath,
  images as cdnImages
} from "src/pages/private/ProjectsModule/utils";

type FormValues = {
  name: string;
  description: string;
  environmentId: string;
};

const defaultValues = {
  description: "",
  name: ""
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: 24,
    "& > .MuiGrid-item": {
      paddingTop: 8
    }
  },
  helperText: {
    color: "#828282",
    display: "inline-block",
    marginBottom: "8px"
  },
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  inputsContainer: {
    width: "calc(50% + 30px)",
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 10.5,
      paddingBottom: 10.5
    }
  },
  submitBtn: {
    height: "36px !important"
  },
  imageSelectionGrid: {
    width: "calc(50% - 30px)"
  },
  link: {
    color: "#003656 !important",
    "&:hover": {
      color: "#003656 !important",
      textDecoration: "underline"
    }
  },
  paper: {
    width: "1000px"
  },
  envStatus: {
    flexShrink: 0
  },
  envMenuItem: {
    maxWidth: "100%"
  }
}));

const overflowStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block"
};

type Props = {
  open: boolean;
  onClose?: () => void;
};

const CreateProject = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const navigate = useNavigate();

  const [toggleUserTenantsRefreshStore] = useTenantsStore(
    (state) => [state.toggleUserTenantsRefresh],
    shallow
  );

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors, isDirty, isValid }
  } = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const inputRef = useRef<HTMLDivElement>(null);

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const createProject = useCreateProject();
  const { isLoading: isEnvironmentsLoading, data: environmentsData } = useEnvironments();
  const { data: environmentTypesResponse } = useGetEnvironmentTypes({
    refetchOnMount: true
  });

  const nameValue = watch("name");
  const selectedEnvId = watch("environmentId");
  const [projectList] = useProjectsStore((state) => [state.projectList]);
  const environmentsTypes = Array.isArray(environmentTypesResponse) ? environmentTypesResponse : [];
  const environments = useMemo(
    () => sortBy(environmentsData, (env) => toLower(env?.name)) || [],
    [environmentsData]
  );

  const { handleSuggestionClick, anchorEl, setAnchorEl, handleSuggestionClose, suggestionsList } =
    useProjectNameSuggestion({
      currName: nameValue,
      setSuggestion: (suggestion: string) => {
        setValue("name", suggestion);
        clearErrors("name");
      }
    });

  useEffect(() => {
    if (environmentsData) {
      const defaultEnv = environmentsData?.find(
        (env: EnvDto) => env?.name === DEFAULT_NAME && env?.defaultFlag
      );
      defaultEnv?.id && setValue("environmentId", defaultEnv.id);
    }
  }, [environmentsData]);

  const handleOnClose = () => {
    reset();
    onClose?.();
  };

  const onSubmit = async (data: FormValues) => {
    setIsCreatingProject(true);

    if (!imageBase64) {
      if (!!process.env.ENVIRONMENT) {
        const randomCdnImagePath = `${cdnImagePath}/${sample(cdnImages)}`;
        handlePostImageSubmit(data, randomCdnImagePath);
      } else {
        const randomImageIndex = random(0, size(offlineImages) - 1);
        imageToBase64(
          offlineImages[randomImageIndex],
          (base64String: string) => {
            handlePostImageSubmit(data, base64String);
          },
          543,
          1084
        );
      }
    } else {
      handlePostImageSubmit(data);
    }
  };

  const handlePostImageSubmit = async (data: FormValues, updatedImage?: string) => {
    if (!selectedEnvId) return;

    const valuesToSend = {
      ...data,
      envId: selectedEnvId,
      image: updatedImage ? updatedImage : imageBase64?.split(";base64,")?.pop()
    };
    createProject.mutate(valuesToSend, {
      onSuccess: (response) => {
        toggleUserTenantsRefreshStore();
        response?.id ? navigate(`/projects/${response?.id}/canvas`) : navigate(WebPaths.Projects);
      },
      onSettled: () => {
        setIsCreatingProject(false);
        onClose?.();
      }
    });
  };

  const handleCloseModal = () => {
    setShowConfirmScreen(false);
    handleOnClose();
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }
    handleOnClose();
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const handleEnvironmentChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const env = environments?.find((item) => item.id === e.target.value) || {};
    env.id && setValue("environmentId", env.id);
  };

  const disabledCreateActionMessage = useMemo(() => {
    if (!!isEnvironmentsLoading) {
      return "Please wait. Fetching environment types under Advanced Settings.";
    }

    if (!!isCreatingProject) {
      return "Please wait. Creating the project.";
    }

    if (!isValid) {
      return "Please ensure all fields are valid to proceed.";
    }

    return "";
  }, [isEnvironmentsLoading, isCreatingProject, isValid]);

  const environmentMenuItem = ({ env, isEnvLink = true }: { env: any; isEnvLink?: boolean }) => {
    const { name: type } = envMetadataFind(environmentsTypes, env) || {};
    return (
      <Box className={clsx(classes.envMenuItem, envMenuItem)}>
        <Grid container direction="column" style={overflowStyles}>
          <Box maxWidth="100%">
            {!!isEnvLink && !!env?.id ? (
              <Link
                className={classes.link}
                to={`/environments/${env?.id}`}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                }}>
                <Text value={env?.name} />
              </Link>
            ) : (
              <OverflowTooltip value={env?.name} title={env?.name} style={overflowStyles} />
            )}
          </Box>

          <span className={classes.envTypeSpan}>
            <EnvironmentTypeConfig
              envType={type}
              cores={env?.cores}
              memInMbs={env?.memInMbs}
              diskInGbs={env?.diskInGbs}
            />
          </span>
        </Grid>
        <div
          className={clsx(
            classes.envStatus,
            roundEnvStatus,
            styles.envStatus,
            styles[env?.launchStatus?.trim()?.toLowerCase()]
          )}>
          {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
            env?.launchStatus ||
            "Unknown"}
        </div>
      </Box>
    );
  };

  return (
    <NewThemeWrapper>
      {showConfirmScreen && isDirty && (
        <ConfirmCloseWindow onConfirm={handleCloseModal} onCancel={handleCancelClose} />
      )}
      <Modal
        isNewTheme
        size={"lg"}
        classes={{ paper: classes.paper }}
        title="Create a new project"
        open={open}
        onClose={handleCloseAttempt}
        onSubmit={handleSubmit(onSubmit)}
        cancelLabel="Cancel"
        submitActionInfo={!!disabledCreateActionMessage ? disabledCreateActionMessage : undefined}
        submitLabel="Create Project"
        isSubmitting={isCreatingProject}
        isSubmitDisabled={!!disabledCreateActionMessage}
        submitDataTestId="submitProjectButton"
        cancelDataTestId="cancelProjectButton">
        <div className={classes.container}>
          <Grid container item className={classes.inputsContainer}>
            <Grid item xs={12}>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                PaperProps={{
                  style: {
                    width: inputRef.current ? inputRef.current.clientWidth : undefined
                  }
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorEl)}
                onClose={handleSuggestionClose}>
                <Typography style={{ padding: "6px 16px", opacity: 0.5 }}>
                  Auto Suggestions
                </Typography>
                {map(suggestionsList, (suggestion) => (
                  <MenuItem
                    key={suggestion}
                    id={suggestion}
                    value={suggestion}
                    onClick={() => handleSuggestionClick(suggestion)}>
                    <OverflowTooltip value={suggestion} title={suggestion} />
                  </MenuItem>
                ))}
              </Menu>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: "Project Name is required",
                  validate: (value) => {
                    if (
                      Boolean(
                        projectList?.find(
                          (project) =>
                            (project as $TSFixMe).name.toLowerCase() === value.toLowerCase().trim()
                        )
                      )
                    ) {
                      setAnchorEl(inputRef?.current);
                      return "This project name already exists";
                    }
                    setAnchorEl(null);
                    const { isValid: isNameValid, error } = validateNameField({
                      fieldName: value,
                      fieldNameLabel: `project name`
                    });
                    if (!isNameValid && error) {
                      return error;
                    }
                    return true;
                  }
                }}
                render={({ field }) => (
                  <Field
                    {...field}
                    inputProps={{ ref: inputRef }}
                    data-testid="projectName"
                    label="Project Name"
                    error={!!errors.name}
                    helperText={errors.name && <>{errors.name?.message}</>}
                    required
                    variant="outlined"
                    size="small"
                  />
                )}
              />
              <Typography variant="caption" className={classes.helperText}>
                This name will be the project identifier.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                rules={{
                  validate: {
                    maximumCharacter: (value: string) =>
                      size(value) <= DescriptionCharacterLimit || DescriptionCharacterLimitMessage
                  }
                }}
                render={({ field, fieldState }) => {
                  const { error } = fieldState;
                  return (
                    <Field
                      {...field}
                      id="description"
                      label="Project description"
                      data-testid="projectDescription"
                      multiline
                      minRows={3}
                      maxRows={3}
                      variant="outlined"
                      error={!!error}
                      {...(!!error?.message ? { helperText: error?.message } : {})}
                    />
                  );
                }}
              />
              <Typography variant="caption" className={classes.helperText}>
                A short description is useful to add context to your project. The description can be
                up to 1500 characters long.
              </Typography>
            </Grid>
            <Button
              style={{ marginBottom: "10px", height: "36px", fontWeight: 400 }}
              color="primary"
              size="small"
              endIcon={
                showAdvanced ? (
                  <ExpandLess style={{ color: "black" }} />
                ) : (
                  <ExpandMore style={{ color: "black" }} />
                )
              }
              onClick={() => setShowAdvanced(!showAdvanced)}>
              Advanced Settings
            </Button>
            {showAdvanced && (
              <Grid item xs={12}>
                <Controller
                  name="environmentId"
                  control={control}
                  rules={{ required: "Environment Type is required" }}
                  render={({ field }) => (
                    <FormControl variant="outlined">
                      <InputLabel shrink htmlFor="environmentId">
                        Environment Type
                      </InputLabel>

                      <SelectWithLoading
                        {...field}
                        label="Environment Type"
                        required
                        isSorted
                        isLoading={isEnvironmentsLoading}
                        data-testid="projectEnvironment"
                        render={(item) =>
                          environmentMenuItem({
                            env: find(environments, { id: item.value }),
                            isEnvLink: false
                          })
                        }
                        {...(!isEnvironmentsLoading && !isEmpty(environments)
                          ? {
                              renderValue: (value) => {
                                // @HACK: This is a hack to handle the case where the environment is not found
                                const selectedEnvId =
                                  value ?? find(environments, { name: DEFAULT_NAME })?.id;

                                return environmentMenuItem({
                                  env: find(environments, { id: selectedEnvId }),
                                  isEnvLink: false
                                });
                              }
                            }
                          : {})}
                        options={map(environments, (item) => ({
                          value: item.id!,
                          label: item.name!
                        }))}
                        onChange={handleEnvironmentChange}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        input={<OutlinedInput notched label="Environment Type" />}
                        displayEmpty
                      />
                    </FormControl>
                  )}
                />
                <Typography
                  variant="caption"
                  className={classes.helperText}
                  style={{ marginBottom: 0 }}>
                  Optionally choose the environment this project will use.
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.imageSelectionGrid}>
            <PreviewImageSelectorNew
              isNewTheme
              previewType="project"
              onChange={setImageBase64}
              defaultImage={default_image}
            />
          </Grid>
        </div>
      </Modal>
    </NewThemeWrapper>
  );
};

export default CreateProject;
